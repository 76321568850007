:root {
  --green: lightgray;
  --dark-green: darkgray;
  --black: rgb(0, 0, 0, 100);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  background: var(--green);
}

@font-face {
  font-family: "Sohne";
  src: url('../public/assets/fonts/normal/test-soehne-buch.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Sohne";
  src: url('../public/assets/fonts/normal/test-soehne-buch-kursiv.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Sohne";
  src: url('../public/assets/fonts/normal/test-soehne-halbfett.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SohneMono";
  src: url('../public/assets/fonts/mono/test-soehne-mono-buch.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SohneMono";
  src: url('../public/assets/fonts/mono/test-soehne-mono-buch-kursiv.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "SohneMono";
  src: url('../public/assets/fonts/mono/test-soehne-mono-halbfett.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

/* ALL SIDEBAR RULES */
.sidebar {
  height: 100dvh;
  border-right: 1px solid var(--dark-green);
  overflow: hidden;
}

.sidebar span {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 1rem;
  color: var(--black);
  text-align: justify;

}

.profile-photo-container {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: 0;
  padding-top: 100%;
  align-items: center;
  justify-content: center;
  background: var(--green);
  border-bottom: 1px solid var(--dark-green);
  z-index: 10;
  overflow: hidden;
}

.profile-photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  mix-blend-mode: darken;
  scale: 1.2;
}

.profile-photo:hover {
  cursor: help;
}

.my-name {
  display: block;
  font-family: "Sohne";
  font-weight: 400;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  color: var(--black);
  text-decoration: none;
  transition: color ease-in-out 1s;
}

.my-name span {
  font-family: "SohneMono";
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  color: var(--black);
  text-decoration: none;
  transition: color ease-in-out 1s;
}


.description {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 0.75rem;
  margin-bottom: 1rem;
  line-height: 1rem;
  text-align: justify;
  cursor: default;
  color: black;
}

.description-bottom {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 0.75rem;
  /* letter-spacing: 0.3px; */
  text-align: justify;
  padding-bottom: 0.5rem;
}

.info {
  padding: 0.75rem 1rem 0rem;
  background: var(--green);
  width: 100%;
  height: calc(100dvh - 4rem);
}

.info h2 {
  font-family: "SohneMono";
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--black);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  cursor: default;
}

.info a {
  cursor: pointer;
}

.project-title {
  font-size: 0.875rem;
  display: inline-block;
  transition: color ease-in-out 1s;
  text-decoration: none;
  color: var(--black);
}

.project-title:hover {
  color: var(--dark-green);
  cursor: pointer;
}

.sidebar-header {
  position: sticky;
  height: 2.5rem;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  background: var(--green);
  border-bottom: 1px solid var(--dark-green);
  padding: 0.75rem 1rem 0.7rem 1rem;
  z-index: 10
}

.sidebar-footer {
  position: sticky;
  height: 2.5rem;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background: var(--green);
  border-top: 1px solid var(--dark-green);
  padding: 0.7rem 1rem 0.75rem 1rem;
  z-index: 10;
}

.footer-link {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 0.75rem;
  transition: color ease-in-out 1s;
  text-decoration: none;
  color: var(--black);
}

.footer-link:hover {
  color: var(--dark-green);
  cursor: pointer;
  animation: colorOscillation 2s infinite alternate;
}

.sidebar p {
  font-family: "Sohne";
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 23.4px;
  color: var(--black);
  margin-bottom: 4px;
}

.sidebar ul {
  font-family: "Sohne";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--black);
  list-style-type: none;
}

.sidebar ul li.project-title {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: var(--black);
  list-style-type: none;
}

/* ALL CONTENT CONTAINER RULES */

.content-container {
  width: calc(100% + 0.5rem);
  height: 100%;
  overflow-y: scroll;
}

.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: -1;
  background: var(--green);
  display: grid;
  grid-template-columns: 2fr 4fr;
  animation: gradient 60s ease infinite alternate-reverse;
}

@keyframes gradient {
  0% {
    background: var(--green)
  }

  50% {
    background: rgb(221, 221, 221);
  }

  100% {
    background: var(--green)
  }
}

.fullscreen {
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  mix-blend-mode: darken;
  scale: 1.5;
}

.video-overlay {
  width: 100%;
  height: 100%;
  z-index: 100;
  background: var(--green);
  transition: opacity ease-in-out 500ms;
  overflow-y: scroll;
  opacity: 0;
  transition: opacity ease-in-out 500ms;
}

.video-overlay.fade-in {
  height: 100%;
  opacity: 1;
}

.project-container {
  padding: 1rem;
  height: fit-content;
}

.project-container p a {
  font-size: 0.75rem;
  text-decoration: underline;
  color: blue;
}

.project-container a {
  text-decoration: underline;
  color: var(--black);

}

.details {
  padding-bottom: 8px;
  /* border-bottom: 1px solid black; */
}

.date {
  animation: sectionAnimation 2s infinite alternate;
}

.project-container h1 {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 12px;
  line-height: auto;
  color: var(--black);
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--dark-green);
  position: sticky;
  padding-top: 1rem;
  margin-top: -2rem;
  top: 0rem;
  background: var(--green);

}

.project-container h1 span {
  font-size: 0.75rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
}

.project-container h2 {
  font-family: "SohneMono";
  font-weight: 600;
  font-size: 12px;
  line-height: auto;
  color: var(--black);
  margin: 8px 0px;
}

.project-container h3 {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 12px;
  line-height: auto;
  color: var(--black);
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: left;
  padding-top: 1rem;
}

.project-container h3 span {
  padding-right: 16px;
}

.project-container h3 span a {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 12px;
  line-height: auto;
  color: blue;
  margin-bottom: 8px;
  display: flex;
  justify-content: left;
}

.project-container h4 {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 12px;
  line-height: auto;
  color: var(--black);
  display: flex;
  justify-content: space-between;
}

.project-container p,
li {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.3px;
  cursor: default;
  color: black;
  text-align: justify;
}


.project-container ul {
  list-style-type: none;
}

.project-img {
  width: 100%;
}


.project-title {
  font-size: 0.75rem;
  display: inline-block;
  letter-spacing: 0.3px;
  cursor: pointer;
  transition: color ease-in-out 1s;
  text-decoration: underline;
  color: blue;
}

.project-title:hover {
  color: var(--dark-green);
}

.contact-info {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 12px;
  line-height: auto;
  color: var(--black);
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--dark-green);
}

.contact-info span {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 12px;
  line-height: auto;
  color: var(--black);
  margin-bottom: 8px;
}

.contact-info span a {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 12px;
  line-height: auto;
  color: blue;
  margin-bottom: 8px;
}

p.contact {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 1rem;
  color: var(--black);
  text-align: justify;
}

p.contact span.bold {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 1rem;
  color: var(--black);
  text-align: justify;
}

p.contact span.bold a {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 0.75rem;
  margin-bottom: 12px;
  color: blue;
}

p.contact a {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 0.75rem;
  margin-bottom: 12px;
  color: blue;
}

.resume-container {
  padding: 1rem;
  height: fit-content;
}

.resume-container a {
  text-decoration: underline;
  color: blue;

}

.resume-container h1 {
  font-family: "SohneMono";
  font-weight: 600;
  font-size: 12px;
  line-height: auto;
  color: var(--black);
  margin-bottom: 8px;
}

.resume-container h2 {
  font-family: "SohneMono";
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: var(--black);
  margin: 16px 0px 0px;
  border-bottom: 1px solid var(--dark-green);
}

.resume-container h4 {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 12px;
  line-height: auto;
  color: var(--black);
  display: flex;
  justify-content: space-between;
  margin: 8px 0px 4px;
}

.resume-container h4 span {
  font-size: 0.75rem;
}

.resume-container h4 span a {
  font-size: 0.75rem;
}

.resume-container p,
li {
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.3px;
  text-align: justify;
  cursor: default;
  color: black;
  text-align: justify;
}

.resume-container ul {
  list-style-type: none;
}

.resume-container h3 {
  color: var(--black);
  font-family: "SohneMono";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-bottom: 0.75rem;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--dark-green);
  padding-top: 8px;
}

.resume-container h3 span {
  font-size: 0.75rem;
}

.resume-container h3 span a {
  font-size: 0.75rem;
}

.bold {
  font-size: 0.75rem;
  font-weight: 600;
}

.bold a {
  font-size: 0.75rem;
  font-weight: 600;
}

.date {
  animation: sectionAnimation 2s infinite alternate;
  font-size: 0.75rem;
  font-weight: 400;
}

.italics {
  font-size: inherit;
  font-style: italic;
  font-weight: 400;
}

.resume-description {
  margin-bottom: 4px;
}

/* The scrollbar itself */
::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: var(--green);
}

::-webkit-scrollbar-thumb {

  background: var(--dark-green);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--dark-green);
}

@keyframes colorOscillation {
  0% {
    color: black;
    ;
  }

  100% {
    color: var(--dark-green);
    ;
  }
}

@keyframes sectionAnimation {
  0% {
    color: black;
    ;
  }

  100% {
    color: var(--dark-green);
    ;
  }
}
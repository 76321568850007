* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.wrapper {
  display: grid;
  grid-template-columns: 2fr 4fr;
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
}

.mobile-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
}

.loader {
  position: fixed;
  top: 0;
  height: 100dvh;
  width: 100dvw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  font-size: 4rem;
  animation: loading 1s infinite linear;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}